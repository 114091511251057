/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  HTTPValidationError,
  NamedEntityReconigitionRequest,
  NamedEntityReconigitionResponse,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    NamedEntityReconigitionRequestFromJSON,
    NamedEntityReconigitionRequestToJSON,
    NamedEntityReconigitionResponseFromJSON,
    NamedEntityReconigitionResponseToJSON,
} from '../models';

export interface GetNerTagsRequest {
    namedEntityReconigitionRequest: NamedEntityReconigitionRequest;
}

/**
 * DefaultApi - interface
 * 
 * @export
 * @interface DefaultApiInterface
 */
export interface DefaultApiInterface {
    /**
     * Use a video transcript to generate metadata tags using NER
     * @summary Use a video transcript to generate metadata tags using NER
     * @param {NamedEntityReconigitionRequest} namedEntityReconigitionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getNerTagsRaw(requestParameters: GetNerTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NamedEntityReconigitionResponse>>;

    /**
     * Use a video transcript to generate metadata tags using NER
     * Use a video transcript to generate metadata tags using NER
     */
    getNerTags(requestParameters: GetNerTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NamedEntityReconigitionResponse>;

}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI implements DefaultApiInterface {

    /**
     * Use a video transcript to generate metadata tags using NER
     * Use a video transcript to generate metadata tags using NER
     */
    async getNerTagsRaw(requestParameters: GetNerTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NamedEntityReconigitionResponse>> {
        if (requestParameters.namedEntityReconigitionRequest === null || requestParameters.namedEntityReconigitionRequest === undefined) {
            throw new runtime.RequiredError('namedEntityReconigitionRequest','Required parameter requestParameters.namedEntityReconigitionRequest was null or undefined when calling getNerTags.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-API-KEY"] = this.configuration.apiKey("X-API-KEY"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ui/ner-tags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NamedEntityReconigitionRequestToJSON(requestParameters.namedEntityReconigitionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NamedEntityReconigitionResponseFromJSON(jsonValue));
    }

    /**
     * Use a video transcript to generate metadata tags using NER
     * Use a video transcript to generate metadata tags using NER
     */
    async getNerTags(requestParameters: GetNerTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NamedEntityReconigitionResponse> {
        const response = await this.getNerTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
