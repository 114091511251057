import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import React, { PropsWithChildren, useState } from 'react';
import {
  Disclosure as HeadlessDisclosure,
  Transition,
} from '@headlessui/react';
import classNames from 'classnames';

type DisclosureClassNames = {
  icon?: string;
  panel?: string;
  container?: string;
  button?: string;
  title?: string;
};

interface DisclosureProps {
  title: string;
  className?: DisclosureClassNames;
  shouldOpen?: boolean;
  onToggle?: () => void;
  onAnimationEnd?: () => void;
}

const ICON_CLASSNAMES = 'rounded-full cursor-pointer text-gray-900 h-5 w-5';

const Disclosure: React.FC<PropsWithChildren<DisclosureProps>> = ({
  children,
  title,
  className,
  shouldOpen,
  onToggle,
  onAnimationEnd,
}) => {
  const isControlled = shouldOpen !== undefined;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isDisclosureOpen = isControlled ? shouldOpen : isOpen;

  const handleToggle = (open: boolean) => {
    if (isControlled) {
      onToggle?.();
    } else {
      setIsOpen(!open);
    }
  };

  return (
    <HeadlessDisclosure
      as="div"
      className={classNames(
        'bg-white rounded-lg border border-gray-200 px-6 py-4 shadow-sm',
        className?.container,
      )}
      defaultOpen={isDisclosureOpen}
    >
      <>
        <HeadlessDisclosure.Button
          onClick={() => handleToggle(isOpen)}
          className={classNames(
            'group flex w-full items-center justify-between',
            className?.button,
          )}
        >
          <p className={classNames('text-sm', className?.title)}>{title}</p>

          {isDisclosureOpen ? (
            <ChevronUpIcon
              className={classNames(ICON_CLASSNAMES, className?.icon)}
            />
          ) : (
            <ChevronDownIcon
              className={classNames(ICON_CLASSNAMES, className?.icon)}
            />
          )}
        </HeadlessDisclosure.Button>
        <div className="w-full">
          <Transition
            show={isDisclosureOpen}
            enter="transition-all duration-300 ease-in-out"
            enterFrom="max-h-0 opacity-0"
            enterTo="max-h-screen opacity-100"
            leave="transition-all duration-300 ease-in-out"
            leaveFrom="max-h-screen opacity-100"
            leaveTo="max-h-0 opacity-0"
            afterLeave={() => {
              onAnimationEnd?.();
            }}
          >
            <HeadlessDisclosure.Panel className="py-2 transition-all duration-300 ease-in-out">
              {children}
            </HeadlessDisclosure.Panel>
          </Transition>
        </div>
      </>
    </HeadlessDisclosure>
  );
};

Disclosure.defaultProps = {
  className: undefined,
  onToggle: undefined,
  shouldOpen: undefined,
  onAnimationEnd: undefined,
};

export default Disclosure;
