import { DefaultApi, GetNerTagsRequest } from 'api/generated/apis/DefaultApi';
import { useAPIContext } from 'context/APIContext';
import { useMutation } from 'react-query';

const getNerTagsMutationKey = ['submitNerData'];

export const useGetNerTagsMutation = () => {
  const { initializeAPI } = useAPIContext();
  return useMutation(
    getNerTagsMutationKey,
    async (variables: GetNerTagsRequest) => {
      const api = await initializeAPI<DefaultApi>(DefaultApi);
      return api.getNerTags(variables);
    },
  );
};
