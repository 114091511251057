/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface NamedEntityReconigitionResponse
 */
export interface NamedEntityReconigitionResponse {
    /**
     * All people-related tags
     * @type {Array<string>}
     * @memberof NamedEntityReconigitionResponse
     */
    people: Array<string>;
    /**
     * All place-related tags
     * @type {Array<string>}
     * @memberof NamedEntityReconigitionResponse
     */
    places: Array<string>;
    /**
     * All thing-related tags
     * @type {Array<string>}
     * @memberof NamedEntityReconigitionResponse
     */
    things: Array<string>;
    /**
     * All miscellaneous tags
     * @type {Array<string>}
     * @memberof NamedEntityReconigitionResponse
     */
    miscellaneous: Array<string>;
}

export function NamedEntityReconigitionResponseFromJSON(json: any): NamedEntityReconigitionResponse {
    return NamedEntityReconigitionResponseFromJSONTyped(json, false);
}

export function NamedEntityReconigitionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NamedEntityReconigitionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'people': json['people'],
        'places': json['places'],
        'things': json['things'],
        'miscellaneous': json['miscellaneous'],
    };
}

export function NamedEntityReconigitionResponseToJSON(value?: NamedEntityReconigitionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'people': value.people,
        'places': value.places,
        'things': value.things,
        'miscellaneous': value.miscellaneous,
    };
}

