import Checkbox from 'components/Checkbox';
import Disclosure from 'components/Disclosure';
import React from 'react';

const DISCLOSURE_CLASS_NAMES = {
  container: '!px-4 !py-2 border shadow-none',
  icon: 'w-[16px] h-[16px]',
  button: 'px-2 py-1 rounded-md',
  title: '!text-md text-gray-800 font-semibold capitalize',
};

interface EntityCategoryGroupProps {
  entityCategory: any;
  entityList: any[];
  setEntityList: (entityList: string[]) => void;
  onAnimationEnd?: () => void;
}

const EntityCategoryGroup: React.FC<EntityCategoryGroupProps> = ({
  entityCategory,
  entityList,
  onAnimationEnd,
  setEntityList,
}) => {
  const handleToggle = (entity: string) => {
    const filteredList = entityList.filter((item) => item !== entity);
    setEntityList(
      entityList.includes(entity) ? filteredList : [...entityList, entity],
    );
  };
  return (
    <Disclosure
      onAnimationEnd={onAnimationEnd}
      key={entityCategory.id}
      className={DISCLOSURE_CLASS_NAMES}
      title={entityCategory.name}
    >
      {entityCategory.items.map((item) => (
        <Checkbox
          onChange={() => handleToggle(item)}
          key={`${entityCategory.id}-${item}`}
          name={item}
          label={item}
          id={`${entityCategory.id}-${item}`}
          checked={entityList.includes(item)}
        />
      ))}
    </Disclosure>
  );
};

EntityCategoryGroup.defaultProps = {
  onAnimationEnd: undefined,
};
export default EntityCategoryGroup;
