import { useEffect } from 'react';

export const useScrollLock = (isOpen: boolean) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    // cleanup to avoid class not being removed on toggle
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [isOpen]);
};
