import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import Button from 'components/Button';
import React, { Fragment, useRef } from 'react';

interface DeleteModalProps {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  title: string;
  body?: React.ReactNode;
  onConfirm: () => void;
  dataTestId?: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  isOpen,
  onClose,
  title,
  body,
  onConfirm,
  dataTestId,
}) => {
  // Workaround for cancel button auto focusing on toggle
  const panelRef = useRef(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <HeadlessDialog
        as="div"
        className="relative z-30"
        initialFocus={panelRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex w-full h-full items-center justify-center p-8">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <HeadlessDialog.Panel className="flex flex-col relative overflow-hidden">
                <div
                  ref={panelRef}
                  tabIndex={-1}
                  className="w-[34rem] bg-white flex justify-center flex-col py-6 px-8 rounded-lg"
                >
                  <h2 className="text-gray-800 text-2xl font-bold whitespace-pre-wrap">
                    {title}
                  </h2>
                  {body}
                  <div className="flex justify-end gap-x-4 mt-6">
                    <Button
                      buttonStyle="secondary"
                      onClick={() => onClose(false)}
                      dataTestId={
                        dataTestId ? `${dataTestId}-cancel-button` : undefined
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      color="bg-red-700 hover:bg-red-800"
                      onClick={onConfirm}
                      dataTestId={
                        dataTestId ? `${dataTestId}-confirm-button` : undefined
                      }
                    >
                      Confirm deletion
                    </Button>
                  </div>
                </div>
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  );
};

DeleteModal.defaultProps = {
  body: undefined,
  dataTestId: undefined,
};

export default DeleteModal;
