import {
  ArrowsPointingOutIcon,
  ArrowTopRightOnSquareIcon,
  SparklesIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';

import { AssetResponse, AssetType, ImageSizeEnum } from 'api/generated';
import classNames from 'classnames';
import ClipboardIconButton from 'components/ClipboardIconButton';
import FadeTransition from 'components/FadeTransition';
import FullScreenAssetModal from 'components/FullScreenAssetModal';
import IconButton from 'components/IconButton';
import ProtectedImage from 'components/Image/ProtectedImage';
import LoadingList from 'components/LoadingList';
import MetadataSection from 'components/Metadata/MetadataSection';
import { useGetImageMetadata } from 'components/Sidebar/AssetDetailSidebar/queries';
import ProtectedVideo from 'components/video/ProtectedVideo';
import { useSidebarAssetContext } from 'context/SidebarAssetContext';
import SidebarContext from 'context/SidebarContext';
import { useUserContext } from 'context/UserContext';
import SimilarImagesGrid from 'pages/datasets/detail/components/SimilarImagesGrid';
import EditConceptVersionLabels from 'pages/queries/EditConceptVersionLabels/EditConceptVersionLabels';
import { useGetDatasetById } from 'queries/datasets';
import { useGetUserById } from 'queries/users';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Button from 'components/Button';
import GenerateTagsModal from 'pages/dynamic-tags/ner-tags/GenerateTagsModal';
import SidebarWrapper from '../SidebarWrapper';

interface AssetDetailSidebarProps {
  asset: Partial<AssetResponse>;
  datasetId: string | null;
  fromDatasetView?: boolean;
  metadata?: { [key: string]: any };
  metadataTitle?: string;
  metadataKeyOrder?: string[];
  defaultMetadataTitle?: string;
  showDefaultMetadata?: boolean;
  videoMetadataTitle?: string;
  // Shows the keyframe data as a video in the sidebar if set to `true`
  showKeyframesAsVideos?: boolean;
  compact?: boolean;
  setSelectedAsset?: (selected: { asset?: AssetResponse }) => void;
  topScoringConceptsCount?: number;
  visibleMetadataFields?: string[];
  setShouldRefetchPredictions?: (shouldRefetchPredictions: boolean) => void;
}

const formatUsername = (username: string) =>
  username.includes('@clients') ? 'API' : username;

const AssetDetailSidebar: React.FunctionComponent<
  PropsWithChildren<AssetDetailSidebarProps>
> = function ImageDetailSidebar({
  compact,
  asset,
  fromDatasetView,
  datasetId,
  metadata,
  metadataTitle,
  metadataKeyOrder,
  defaultMetadataTitle,
  setSelectedAsset,
  showDefaultMetadata,
  videoMetadataTitle,
  showKeyframesAsVideos,
  topScoringConceptsCount,
  visibleMetadataFields,
  setShouldRefetchPredictions,
}) {
  const { rightSidebarOpen, setRightSidebarOpen } = useContext(SidebarContext);
  const [isGenerateTagsModalOpen, setIsGenerateTagsModalOpen] =
    useState<boolean>(false);
  const { selectedAsset: sidebarAsset, setSelectedAsset: setSidebarAsset } =
    useSidebarAssetContext();
  const { data, isLoading } = useGetImageMetadata(
    asset.coactiveImageId,
    showDefaultMetadata,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { isTrialUser } = useUserContext();
  const { data: datasetData } = useGetDatasetById(datasetId!);
  const contentContainerRef = useRef<HTMLDivElement>(null);
  const { data: createdBy } = useGetUserById(datasetData?.createdUserId);
  const defaultMetadata = useMemo(() => {
    if (!data) {
      return undefined;
    }
    const ret: { [key: string]: string | undefined } = { ...data?.metadata };
    if (
      (!showKeyframesAsVideos || data.assetType === 'image') &&
      data?.coactiveImageId
    ) {
      ret.coactive_image_id = data.coactiveImageId;
    }
    if (data?.assetType) {
      ret.asset_type =
        data?.assetType === 'keyframe' && showKeyframesAsVideos
          ? 'video'
          : data.assetType;
    }
    if (data?.createdDt) {
      ret.created_dt = data.createdDt;
    }
    if (data?.updatedDt) {
      ret.updated_dt = data.updatedDt;
    }
    if (metadata) {
      Object.entries(ret).forEach(([key]) => {
        if (metadata[key] !== undefined && metadata[key] !== null) {
          delete ret[key];
        }
      });
    }
    return ret;
  }, [data, metadata]);

  const videoMetadata = useMemo(() => {
    if (!showKeyframesAsVideos && data) {
      if (data.audioSegment) {
        return {
          audio: data?.audioSegment.speechToTextTranscription.trim(),
          start_time_ms: data?.audioSegment.startTimeMs,
          end_time_ms: data?.audioSegment.endTimeMs,
        };
      }
      return {
        start_time_ms: data?.shot?.startTimeMs,
        end_time_ms: data?.shot?.endTimeMs,
      };
    }
    return undefined;
  }, [data, metadata, showKeyframesAsVideos]);

  const pathMetadata = {
    path: data?.path || metadata?.path,
  };

  const isVideo = asset?.assetType === AssetType.Keyframe && asset.video?.url;
  const isImage = asset?.assetType === AssetType.Image;

  const handleCloseSideBar = () => {
    setRightSidebarOpen(false);
    setSidebarAsset(undefined);
  };

  const resetScroll = () => {
    if (
      contentContainerRef.current &&
      contentContainerRef.current.scrollTop !== 0
    ) {
      contentContainerRef.current.scrollTop = 0;
    }
  };

  const handleSelectAsset = (selectedAsset: AssetResponse) => {
    if (setSelectedAsset) {
      setSidebarAsset(selectedAsset);
      setSelectedAsset({ asset: selectedAsset });
    }
  };

  useEffect(() => {
    resetScroll();
  }, [sidebarAsset]);

  const hasEditConcepts = (isImage || !fromDatasetView) && !isTrialUser;

  const baseUrl = window.location.origin;

  const getUrlForAssetType = (assetType?: AssetType) => {
    switch (assetType) {
      case AssetType.Image:
        return `/datasets/${datasetId}/images/${asset.coactiveImageId}`;
      case AssetType.Keyframe:
        return `/datasets/${datasetId}/videos/${asset.coactiveImageId}`;

      default:
        return '';
    }
  };

  const handlePreviewClick = () => {
    if (!isImage) return;
    setIsModalOpen(true);
  };

  console.log(asset);

  return (
    <>
      {isGenerateTagsModalOpen && (
        <GenerateTagsModal
          datasetId={datasetId!}
          videoId={asset.video?.id!}
          isOpen={isGenerateTagsModalOpen}
          setIsOpen={setIsGenerateTagsModalOpen}
        />
      )}
      <FullScreenAssetModal
        asset={asset}
        open={isModalOpen}
        setOpen={setIsModalOpen}
      />
      <SidebarWrapper
        compact={compact}
        header={
          <>
            <IconButton
              alt="open-details"
              Icon={ArrowTopRightOnSquareIcon}
              onClick={() => window.open(getUrlForAssetType(asset?.assetType))}
              tooltip="Open in new tab"
            />
            <IconButton
              alt="close-sidebar"
              Icon={XMarkIcon}
              onClick={handleCloseSideBar}
              size="medium"
              tooltip="Close"
            />
          </>
        }
        footer={
          <ClipboardIconButton
            color="text-gray-900"
            hoverColor="hover:gray-900"
            iconType="share"
            id="share"
            padding="p-0"
            size="large"
            text={`${baseUrl}${getUrlForAssetType(asset?.assetType)}`}
            tooltipText="Copy link to share"
          />
        }
        rightSidebarOpen={rightSidebarOpen}
      >
        <div className="overflow-y-auto" ref={contentContainerRef}>
          <div
            className={classNames(
              'flex h-[25rem] items-center justify-center max-h-[40vh] bg-slate-100 relative',
              isVideo ? 'items-stretch cursor-default' : '',
              isImage ? 'cursor-pointer' : '',
            )}
            onClick={handlePreviewClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') handlePreviewClick();
            }}
            role="button"
            tabIndex={0}
          >
            <div className="absolute top-6 right-6 z-30">
              <IconButton
                alt="expand"
                background="bg-slate-50 bg-opacity-75 hover:bg-slate-200"
                onClick={() => setIsModalOpen(true)}
                hoverColor="hover:bg-slate-200"
                Icon={ArrowsPointingOutIcon}
                rounded="full"
                tooltip="Expand"
              />
            </div>
            {isImage && asset.previewImages?.regular?.url && (
              <ProtectedImage
                url={asset.previewImages?.regular?.url}
                sensitive={asset.sensitive}
                className="h-full w-auto max-h-[40vh] max-w-full !static"
                size={ImageSizeEnum.Regular}
                coactiveImageId={asset.coactiveImageId}
                placeholder="black"
                objectFit="object-contain"
                preventHeightJump
              />
            )}
            {isVideo && asset.previewImages && asset.video?.url && (
              <ProtectedVideo
                posterImageUrl={asset.previewImages.regular.url}
                url={asset.video.url}
                startTime={
                  showKeyframesAsVideos
                    ? undefined
                    : asset.audioSegment?.startTimeMs ?? asset.shot?.startTimeMs
                }
                endTime={
                  showKeyframesAsVideos
                    ? undefined
                    : asset.audioSegment?.endTimeMs ?? asset.shot?.endTimeMs
                }
                className="max-h-full min-h-40"
              />
            )}
          </div>
          <div className="flex-1">
            <div className="pt-2 px-8 pb-0 text-sm">
              <FadeTransition show={!isLoading} appear>
                <>
                  <div className="w-full flex justify-center">
                    <Button
                      className=""
                      onClick={() => setIsGenerateTagsModalOpen(true)}
                    >
                      <SparklesIcon className="text-white h-4 w-4 mr-2" />{' '}
                      Generate dynamic tags
                    </Button>
                  </div>
                  <MetadataSection metadata={pathMetadata} singleField />
                  {asset.coactiveImageId && datasetId && hasEditConcepts && (
                    <div className="text-center">
                      <EditConceptVersionLabels
                        setShouldRefetchPredictions={
                          setShouldRefetchPredictions
                        }
                        coactiveImageId={asset.coactiveImageId}
                        datasetId={datasetId}
                        topScoringConceptsCount={topScoringConceptsCount}
                      />
                    </div>
                  )}
                  <MetadataSection
                    keyOrder={metadataKeyOrder}
                    metadata={metadata}
                    title={metadataTitle ?? 'Metadata'}
                    visibleFields={visibleMetadataFields}
                  />
                  {isVideo && (
                    <MetadataSection
                      metadata={videoMetadata}
                      title={videoMetadataTitle ?? ''}
                    />
                  )}
                  <MetadataSection
                    metadata={defaultMetadata}
                    noClipboardIcon={['created_dt', 'updated_dt']}
                    title={defaultMetadataTitle ?? 'Metadata'}
                  />
                  <div className="mb-8 text-sm">
                    <p className="text-gray-500 font-medium mb-2">Created by</p>
                    <p>{formatUsername(createdBy?.displayName || '')}</p>
                  </div>
                  {isImage && setSelectedAsset && asset && (
                    <SimilarImagesGrid
                      datasetId={datasetId}
                      imageId={
                        data?.coactiveImageId || metadata?.coactive_image_id
                      }
                      onClick={(a) => handleSelectAsset(a)}
                    />
                  )}
                </>
              </FadeTransition>
              <FadeTransition show={isLoading} appear enterDelayMs={500}>
                {isLoading && <LoadingList />}
              </FadeTransition>
            </div>
          </div>
        </div>
      </SidebarWrapper>
    </>
  );
};

AssetDetailSidebar.defaultProps = {
  compact: false,
  fromDatasetView: false,
  metadata: undefined,
  metadataTitle: 'Metadata',
  metadataKeyOrder: undefined,
  showDefaultMetadata: true,
  defaultMetadataTitle: 'Metadata',
  videoMetadataTitle: 'Video Metadata',
  setSelectedAsset: undefined,
  showKeyframesAsVideos: false,
  topScoringConceptsCount: undefined,
  visibleMetadataFields: undefined,
  setShouldRefetchPredictions: undefined,
};

export default AssetDetailSidebar;
