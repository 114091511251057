/* tslint:disable */
/* eslint-disable */
/**
 * Coactive
 * Coactive api allows you to interact will all aspects of the coactive platform and functionality.
 *
 * The version of the OpenAPI document: 0.1.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Base model for all schema
 * @export
 * @interface NamedEntityReconigitionRequest
 */
export interface NamedEntityReconigitionRequest {
    /**
     * Coactive video ID of the entity to perform NER on
     * @type {string}
     * @memberof NamedEntityReconigitionRequest
     */
    videoId: string;
    /**
     * Value of confidence threshold
     * @type {number}
     * @memberof NamedEntityReconigitionRequest
     */
    threshold: number;
}

export function NamedEntityReconigitionRequestFromJSON(json: any): NamedEntityReconigitionRequest {
    return NamedEntityReconigitionRequestFromJSONTyped(json, false);
}

export function NamedEntityReconigitionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NamedEntityReconigitionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'videoId': json['videoId'],
        'threshold': json['threshold'],
    };
}

export function NamedEntityReconigitionRequestToJSON(value?: NamedEntityReconigitionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'videoId': value.videoId,
        'threshold': value.threshold,
    };
}

