import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import {
  ArrowRightIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import TextInput from 'components/TextInput';
import { useScrollLock } from 'hooks/useScrollLock';
import React, { useState } from 'react';
import { useGetNerTagsMutation } from 'queries/ner';
import { useCreateCategoryMutation } from 'queries/dynamic-tags';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import colors from 'tailwindcss/colors';
import EntityCategoryGroup from './EntityCategoryGroup';

interface GenerateTagsModalProps {
  datasetId: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  videoId: string;
}

const MOCK_RESPONSE = {
  people: [
    'Po',
    'Master Oogway',
    'Tigress',
    'Viper',
    'Crane',
    'Snake',
    'Mantis',
    'Iron Ox',
    'Dragon Warrior',
  ],
  places: ['Valley of Peace'],
  things: ['souvenir', 'noodles'],
  miscellaneous: ['fire', 'accident'],
};

const GenerateTagsModal: React.FC<GenerateTagsModalProps> = ({
  datasetId,
  isOpen,
  setIsOpen,
  videoId,
}) => {
  const [thresholdValue, setThresholdValue] = useState(70);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [groupName, setGroupName] = useState<string>('');
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const [entityList, setEntityList] = useState<string[]>(
    Object.values(MOCK_RESPONSE).flat(),
  );

  const { mutate: getNerTags, isLoading: isGeneratingNerTags } =
    useGetNerTagsMutation();
  const { mutate: createCategory, isLoading: isCreatingCategory } =
    useCreateCategoryMutation();

  const handleChange = (event) => {
    // eslint-disable-next-line no-console
    console.log(groupName); // temp workaround to use groupName and fix the startui error
    setThresholdValue(event.target.value);
  };

  useScrollLock(isOpen);

  const entityCategories = Object.keys(MOCK_RESPONSE).map((category) => ({
    id: category,
    name: category,
    items: MOCK_RESPONSE[`${category}`],
  }));

  const handleGetNerTags = () => {
    getNerTags(
      {
        namedEntityReconigitionRequest: {
          videoId,
          threshold: thresholdValue,
        },
      },
      {
        onSuccess: (response: any) => {
          if (error) {
            setError(null);
          }
          console.log(response);
          setCurrentStep(2);
        },
        onError: async () => {
          setError(
            'Something went wrong while generating your dynamic tags. Please try again.',
          );
        },
      },
    );
  };

  console.log(handleGetNerTags);

  const createTagPayload = {
    createCategoryRequest: {
      name: groupName!,
      datasetId: datasetId!,
      dynamicTags: entityList.reduce(
        (acc, entity) => ({
          ...acc,
          [entity]: [entity],
        }),
        {},
      ),
    },
  };

  const handleCreateCategory = () => {
    createCategory(createTagPayload, {
      onSuccess: (response: any) => {
        navigate(`/dynamic-tag-categories/${response.categoryId}`);
      },
      onError: () => {
        setError(
          'Something went wrong while generating your dynamic tags. Please try again.',
        );
      },
    });
  };

  return (
    <div className="fixed top-0 left-0 min-h-screen w-screen bg-white z-[99] overflow-auto">
      <div className="text-gray-800 w-full flex items-center justify-between border-b border-gray-100 px-4 py-3">
        <IconButton Icon={XMarkIcon} onClick={() => setIsOpen(false)} />
        <h1 className="font-medium">Generate dynamic tags</h1>
        <Button
          onClick={() => setIsOpen(false)}
          buttonStyle="secondary"
          rightIcon={ArrowRightIcon}
        >
          Generate tags
        </Button>
      </div>
      <div className="pt-16 w-full max-h-[calc(100vh-4rem)] overflow-y-auto">
        <div className="flex justify-center w-1/2 mx-auto flex-col  gap-y-8">
          {currentStep === 1 ? (
            <>
              <p>
                Automatic tags are created based on the people, places, and
                objects that appear in the audio transcript for this video.
              </p>
              <div className="space-y-4">
                <h2 className="text-gray-800 font-semibold m-0">
                  Select threshold
                </h2>
                <p className="text-sm m-0">
                  The threshold determines how confident the system needs to be
                  before it identifies something.
                </p>
                <div className="flex w-full ">
                  <input
                    className="accent-emerald-600 flex flex-grow"
                    type="range"
                    min="0"
                    max="100"
                    value={thresholdValue}
                    onChange={handleChange}
                  />
                  <TextInput
                    name="threshold-input"
                    containerClassName="w-24 ml-5"
                    type="number"
                    id="threshold-input"
                    value={String(thresholdValue)}
                    onChange={(e) => setThresholdValue(Number(e.target.value))}
                  />
                </div>
              </div>
              {error && (
                <div className=" text-sm flex items-center rounded-md p-4 bg-yellow-50 border border-yellow-500">
                  <ExclamationCircleIcon className="text-yellow-500 h-6 w-6 flex mr-2" />
                  {error}
                </div>
              )}
              <div className="w-full justify-between flex">
                <Button
                  buttonStyle="secondary"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isGeneratingNerTags}
                  rightIcon={!isGeneratingNerTags ? ArrowRightIcon : undefined}
                  onClick={
                    // handleGetNerTags
                    () => setCurrentStep(2)
                  }
                >
                  {isGeneratingNerTags
                    ? 'Generating tags...'
                    : '  Generate tags'}

                  {isGeneratingNerTags && (
                    <ClipLoader
                      className="ml-2"
                      color={colors.gray['400']}
                      loading
                      size={20}
                      speedMultiplier={0.75}
                    />
                  )}
                </Button>
              </div>
            </>
          ) : (
            <>
              <div>
                <TextInput
                  label="Group name"
                  onChange={(e) => setGroupName(e.target.value)}
                  name="group-name-input"
                  id="group-name-input"
                />
                <div className="flex justify-between mt-1 text-gray-600 text-xs">
                  <p>
                    Group names can only contain alphanumeric characters and
                    spaces.
                  </p>
                  <p>Maximum 80 characters</p>
                </div>
              </div>
              <div className="py-2 space-y-4">
                {entityCategories.map((entityCategory) => (
                  <EntityCategoryGroup
                    setEntityList={setEntityList}
                    entityList={entityList}
                    key={entityCategory.id}
                    entityCategory={entityCategory}
                  />
                ))}
              </div>
              <div className="w-full justify-between flex">
                <Button
                  icon={ArrowLeftIcon}
                  buttonStyle="secondary"
                  onClick={() => setCurrentStep(1)}
                >
                  Back
                </Button>
                <Button
                  disabled={isCreatingCategory}
                  rightIcon={!isCreatingCategory ? ArrowRightIcon : undefined}
                  onClick={handleCreateCategory}
                >
                  {isCreatingCategory
                    ? 'Generating tags...'
                    : '  Generate tags'}
                  {isCreatingCategory && (
                    <ClipLoader
                      className="ml-2"
                      color={colors.gray['400']}
                      loading
                      size={20}
                      speedMultiplier={0.75}
                    />
                  )}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GenerateTagsModal;
